import { CartItem } from "../../../types/cart";
import ChooseDeliverySlot from "./choose-delivery-slot";
import { useTranslation } from "react-i18next";
import AddToFavouriteButton from "../../home/add-to-favourite-button";
import ChooseSlaughterDay from "../checkout-sacrifices/choose-slaughter-day";
import { getCurrency } from "../../../lib/middleware";
import { useState, useEffect } from "react";

export default function CheckoutItem({
    cartItem,
    isSlaughter = false,
}: {
    cartItem: CartItem;
    isSlaughter?: boolean;
}) {
    const { t } = useTranslation();

    const currency = getCurrency();

    return (
        <div className="flex flex-col gap-4 rounded-xl border border-[rgba(229,229,229,1)] bg-white p-4">
            <span className="text-xl font-medium text-[rgba(23,23,23,1)]">
                {cartItem.department.name}
            </span>
            {cartItem.details.map((detail) => (
                <div
                    key={detail.id}
                    className="flex gap-2 rounded-lg border border-[rgba(245,245,245,1)] bg-[rgba(250,250,250,1)] p-4"
                >
                    <div>
                        <img
                            src={detail.product.image}
                            alt={detail.product.name}
                            className="h-[104px] w-[130px] rounded-[4px] object-cover"
                        />
                    </div>
                    <div className="flex-1">
                        <div className="mb-5 flex items-start justify-between">
                            <div className="flex flex-col gap-1.5">
                                <span className="text-lg font-medium text-primary">
                                    {detail.product.name}
                                </span>
                                <span className="text-sm font-light">
                                    {detail.category.name}
                                </span>
                            </div>

                            <AddToFavouriteButton
                                productId={detail.product.id}
                                in_favourite={detail.product.in_favourite}
                            />
                        </div>
                        <div className="flex items-center justify-between">
                            <span className="text-lg font-medium text-[rgba(64,64,64,1)]">
                                +{+detail.product.price * detail.quantity}{" "}
                                {t(currency || "AED")}
                            </span>
                            <div className="flex size-9 items-center justify-center rounded-full bg-white text-lg font-medium text-[rgba(24,24,27,1)] shadow-[0px_3px_6px_0px_rgba(26,26,26,0.16)]">
                                <span className="pb-1">{+detail.quantity}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {!isSlaughter && (
                <ChooseDeliverySlot cart_id={cartItem.id.toString()} />
            )}
            {isSlaughter && (
                <ChooseSlaughterDay cart_id={cartItem.id.toString()} />
            )}
        </div>
    );
}
