import React from "react";
import { cn } from "../../lib/utils";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import LimitedTimeOffer from "./LimitedTimeOffer";
import AddToFavouriteButton from "../home/add-to-favourite-button";
import { getCurrency } from "../../lib/middleware";

const TopSection = ({ data }) => {
    const { i18n, t } = useTranslation();
    const currency = getCurrency();

    let discountPrecentage = 0;
    if (data?.old_price && data?.price) {
        discountPrecentage =
            (1 - Number(data?.price) / Number(data?.old_price)) * 100;
    }

    return (
        <>
            <h2 className="mb-10 text-center text-3xl font-medium text-[rgba(23,23,23,1)] xl:text-[40px]">
                {data.name}
            </h2>
            <div className="mb-3 flex items-center gap-x-1.5">
                <div className="ms-auto">
                    <AddToFavouriteButton
                        in_favourite={data.in_favourite}
                        productId={data.id}
                    />
                </div>
            </div>
            <div
                className={cn("relative mb-3 h-[472px]", {
                    "rounded-xl border border-[rgba(240,61,67,0.4)]":
                        !!data.limited_offer.has_limited_offer,
                })}
            >
                <div className="absolute start-0 top-4 mb-4 flex items-start justify-between">
                    <div className="flex flex-col items-start gap-2">
                        {data.badge && (
                            <div className="relative start-2 rounded-md bg-[rgba(240,61,67,1)] p-2 pb-3 text-xs font-semibold leading-none text-white shadow-[0px_2.87px_5.75px_0px_rgba(26,26,26,0.16)]">
                                {data.badge.name}
                            </div>
                        )}
                        {data.badges &&
                            data.badges.length > 0 &&
                            data.badges.map((badge) => (
                                <div
                                    key={badge.id}
                                    className="relative start-2 rounded-md bg-[rgba(240,61,67,1)] p-2 pb-3 text-xs font-semibold leading-none text-white shadow-[0px_2.87px_5.75px_0px_rgba(26,26,26,0.16)]"
                                >
                                    {badge.name}
                                </div>
                            ))}
                        {!!data.limited_offer.has_limited_offer && (
                            <LimitedTimeOffer data={data} />
                        )}
                    </div>
                </div>

                {data.old_price && data.old_price !== "0.00" && (
                    <div
                        className="absolute bottom-4 start-4 ms-auto w-fit rounded-md bg-primary p-2 pb-3 text-xs font-semibold leading-none text-white shadow-[0px_2.87px_5.75px_0px_rgba(26,26,26,0.16)]"
                        dir="ltr"
                    >
                        -{discountPrecentage.toFixed(0)}%
                    </div>
                )}
                <img
                    src={data.image}
                    alt={data.name}
                    style={{ backgroundColor: "#fffcf3" }}
                    className="h-full w-full rounded-xl object-contain"
                />
            </div>
            <div className="mb-3 flex flex-wrap items-center justify-between gap-3 text-2xl font-medium text-primary xl:mb-1">
                <span>{data.name}</span>
                <span>
                    {data.price} {t(currency || "AED")}
                </span>
            </div>
            <div className="mb-[20px] flex flex-wrap items-center justify-between gap-3 text-xl text-[rgba(115,115,115,1)]">
                <span>{data.shop?.name || data.department.name}</span>
                <span>{data.category.name}</span>
            </div>
            <div className="mb-[40px] flex flex-wrap items-center justify-between gap-3 text-xl text-[rgba(115,115,115,1)]">
                <p className="text-[15px] text-[rgba(82,82,82,1)]">
                    {data.description}
                </p>
            </div>
        </>
    );
};

export default TopSection;
