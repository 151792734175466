import { useTranslation } from "react-i18next";
import { cn, toArabic } from "../../lib/utils";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Product from "../../types/product";
import useModalsStore from "../../store/modalsStore";
import Cookies from "js-cookie";
import axios from "axios";
import AddToFavouriteButton from "./add-to-favourite-button";
import { getCurrency } from "../../lib/middleware";

export default function ProductCard({ product }: { product: Product }) {
    let _remainingDays = 0,
        _remainingHours = 0,
        _remainginMinutes = 0,
        _remainginSeconds = 0,
        isExpired = false;
    if (
        product.limited_offer.has_limited_offer &&
        product.limited_offer.limited_offer_expired_at
    ) {
        const expiredAt = new Date(
            product.limited_offer.limited_offer_expired_at,
        );
        const now = new Date();
        const diff = expiredAt.getTime() - now.getTime();
        isExpired = diff < 0;
        _remainingDays = Math.floor(diff / (1000 * 60 * 60 * 24));
        _remainingHours = Math.floor(
            (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );
        _remainginMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        _remainginSeconds = Math.floor((diff % (1000 * 60)) / 1000);
    }
    let discountPrecentage = 0;
    if (product.old_price && product.price) {
        discountPrecentage =
            (1 - Number(product.price) / Number(product.old_price)) * 100;
    }

    const [remainingDays, setRemainingDays] = useState(_remainingDays);
    const [remainingHours, setRemainingHours] = useState(_remainingHours);
    const [remainginMinutes, setRemainginMinutes] = useState(_remainginMinutes);
    const [remainginSeconds, setRemainginSeconds] = useState(_remainginSeconds);
    const currency = getCurrency() ? getCurrency() : "";
    useEffect(() => {
        if (isExpired || !product.limited_offer.has_limited_offer) return;

        const interval = setInterval(() => {
            if (isExpired) return;
            if (remainginSeconds > 0) {
                setRemainginSeconds(remainginSeconds - 1);
            } else {
                if (remainginMinutes > 0) {
                    setRemainginMinutes(remainginMinutes - 1);
                    setRemainginSeconds(59);
                } else {
                    if (remainingHours > 0) {
                        setRemainingHours(remainingHours - 1);
                        setRemainginMinutes(59);
                    } else {
                        if (remainingDays > 0) {
                            setRemainingDays(remainingDays - 1);
                            setRemainingHours(23);
                        }
                    }
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [
        remainginSeconds,
        remainginMinutes,
        remainingHours,
        remainingDays,
        isExpired,
        product.limited_offer.has_limited_offer,
    ]);

    const { t } = useTranslation();

    return (
        <Link
            to={`/product/${product.id}`}
            className={cn(
                "relative flex h-full flex-col rounded-xl border border-[rgba(229,229,229,1)] bg-white p-2",
                {
                    "border-[rgba(240,61,67,0.4)]":
                        !!product.limited_offer.has_limited_offer && !isExpired,
                },
            )}
        >
            <div className="relative mb-3.5 rounded-lg bg-[rgba(250,250,250,1)]">
                <div className="absolute end-1 start-0 top-1 mb-4 flex items-start justify-between">
                    <div className="flex flex-col items-start gap-2">
                        {product.badge && (
                            <div className="relative start-2 rounded-md bg-[rgba(240,61,67,1)] p-2 pb-3 text-xs font-semibold leading-none text-white shadow-[0px_2.87px_5.75px_0px_rgba(26,26,26,0.16)]">
                                {product.badge.name}
                            </div>
                        )}
                        {product.badges &&
                            product.badges.length > 0 &&
                            product.badges.map((badge) => (
                                <div
                                    key={badge.id}
                                    className="relative start-2 rounded-md bg-[rgba(240,61,67,1)] p-2 text-xs font-semibold leading-none text-white shadow-[0px_2.87px_5.75px_0px_rgba(26,26,26,0.16)]"
                                >
                                    {badge.name}
                                </div>
                            ))}
                        {!!product.limited_offer.has_limited_offer &&
                            !isExpired && (
                                <div className="relative flex items-center justify-center gap-x-0.5 bg-[rgba(240,61,67,1)] py-1.5 pe-6 ps-2 text-xs font-medium leading-none text-white [clipPath:polygon(100%_0,90%_50%,100%_100%,0%_100%,0_50%,0%_0%)] rtl:[clipPath:polygon(0%_0,10%_50%,0%_100%,100%_100%,100%_50%,100%_0%)]">
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.70826 2.40625C8.1868 2.40625 6.69951 2.85742 5.43446 3.70269C4.16942 4.54797 3.18343 5.7494 2.6012 7.15504C2.01896 8.56069 1.86662 10.1074 2.16344 11.5996C2.46026 13.0919 3.19291 14.4626 4.26875 15.5384C5.34458 16.6142 6.71528 17.3469 8.20751 17.6437C9.69973 17.9405 11.2465 17.7882 12.6521 17.206C14.0578 16.6237 15.2592 15.6377 16.1045 14.3727C16.9497 13.1076 17.4009 11.6203 17.4009 10.0989C17.3987 8.05933 16.5876 6.10393 15.1454 4.66175C13.7032 3.21957 11.7478 2.4084 9.70826 2.40625ZM9.70826 16.608C8.42088 16.608 7.1624 16.2263 6.09197 15.5111C5.02155 14.7958 4.18725 13.7792 3.69459 12.5898C3.20193 11.4004 3.07303 10.0917 3.32418 8.82901C3.57534 7.56636 4.19528 6.40654 5.1056 5.49622C6.01592 4.5859 7.17574 3.96596 8.43839 3.7148C9.70104 3.46365 11.0098 3.59255 12.1992 4.08521C13.3886 4.57787 14.4052 5.41217 15.1204 6.48259C15.8357 7.55302 16.2174 8.8115 16.2174 10.0989C16.2155 11.8246 15.529 13.4791 14.3088 14.6994C13.0885 15.9197 11.434 16.6061 9.70826 16.608ZM14.4422 10.0989C14.4422 10.2558 14.3799 10.4063 14.2689 10.5173C14.1579 10.6283 14.0074 10.6906 13.8505 10.6906H9.70826C9.55133 10.6906 9.40081 10.6283 9.28984 10.5173C9.17887 10.4063 9.11652 10.2558 9.11652 10.0989V5.9567C9.11652 5.79976 9.17887 5.64925 9.28984 5.53827C9.40081 5.4273 9.55133 5.36496 9.70826 5.36496C9.8652 5.36496 10.0157 5.4273 10.1267 5.53827C10.2377 5.64925 10.3 5.79976 10.3 5.9567V9.50714H13.8505C14.0074 9.50714 14.1579 9.56949 14.2689 9.68046C14.3799 9.79144 14.4422 9.94195 14.4422 10.0989Z"
                                            fill="white"
                                        />
                                    </svg>

                                    <span className="tabular-nums leading-none">
                                        {remainingDays < 10
                                            ? `0${remainingDays}`
                                            : remainingDays}
                                        :
                                        {remainingHours < 10
                                            ? `0${remainingHours}`
                                            : remainingHours}
                                        :
                                        {remainginMinutes < 10
                                            ? `0${remainginMinutes}`
                                            : remainginMinutes}
                                        :
                                        {remainginSeconds < 10
                                            ? `0${remainginSeconds}`
                                            : remainginSeconds}
                                    </span>
                                </div>
                            )}
                    </div>
                    <AddToFavouriteButton
                        in_favourite={product.in_favourite}
                        productId={product.id}
                    />
                </div>
                <div className="h-[245px] rounded-lg">
                    <img
                        src={product.image}
                        alt={product.name}
                        className="h-full w-full rounded-lg object-cover"
                    />
                </div>
                {product.old_price && (
                    <div
                        className="absolute bottom-2 start-2 ms-auto w-fit rounded-md bg-primary p-2 text-xs font-semibold leading-none text-white shadow-[0px_2.87px_5.75px_0px_rgba(26,26,26,0.16)]"
                        dir="ltr"
                    >
                        -{discountPrecentage.toFixed(0)}%
                    </div>
                )}
            </div>
            <div className="mb-1 text-sm text-primary">
                {product.shop?.name}
            </div>
            <div className="mb-1 font-medium text-[rgba(23,23,23,1)]">
                {product.name}
            </div>
            <div className="text-s mb-1.5 font-light text-[rgba(115,115,115,1)]">
                {product.category.name}
            </div>
            <div className="text-lg font-medium text-[rgba(23,23,23,1)]">
                {toArabic(product.price)} {t(currency || "AED")}
            </div>
            {product.old_price && (
                <div className="text-lg text-[rgba(115,115,115,1)] line-through">
                    {toArabic(product.old_price)} {t(currency || "AED")}
                </div>
            )}

            <button className="absolute bottom-3.5 end-3.5 ms-auto rounded-full border border-[rgba(229,229,229,1)] p-1 shadow-[0px_4.33px_4.33px_0px_rgba(0,0,0,0.07)] transition hover:scale-125">
                <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.6183 19.6493C11.604 20.2603 11.0991 20.7452 10.488 20.7347C9.87693 20.7242 9.38893 20.2223 9.39557 19.6112C9.40222 19.0001 9.90101 18.5089 10.5122 18.5117C11.1312 18.5218 11.6256 19.0303 11.6183 19.6493Z"
                        stroke="#525252"
                        strokeWidth="1.23816"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.5441 19.6493C17.5298 20.2596 17.026 20.7442 16.4156 20.7347C15.8052 20.7252 15.3167 20.2253 15.3213 19.6148C15.326 19.0044 15.8221 18.5119 16.4325 18.5117C16.7308 18.5151 17.0155 18.6369 17.2239 18.8503C17.4324 19.0636 17.5476 19.351 17.5441 19.6493Z"
                        stroke="#525252"
                        strokeWidth="1.23816"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.5949 8.65625H10.8769L10.1358 14.7233C10.1312 15.1209 10.2848 15.5042 10.5627 15.7886C10.8407 16.0731 11.2202 16.2354 11.6179 16.24H18.1876C18.7121 16.2379 19.196 15.9574 19.4584 15.5033L22.8625 10.953C23.1406 10.4895 23.151 9.91303 22.8898 9.43977C22.6286 8.96651 22.1353 8.66801 21.5949 8.65625V8.65625Z"
                        stroke="#525252"
                        strokeWidth="1.23816"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10.2755 8.80147C10.3567 9.13362 10.6917 9.33712 11.0238 9.256C11.356 9.17488 11.5595 8.83987 11.4784 8.50772L10.2755 8.80147ZM10.1673 5.74893L10.7687 5.60206L10.7685 5.60116L10.1673 5.74893ZM10.0048 5.62109L10.0069 5.00201H10.0048V5.62109ZM7.17283 5.00201C6.83092 5.00201 6.55375 5.27918 6.55375 5.62109C6.55375 5.963 6.83092 6.24018 7.17283 6.24018V5.00201ZM7.76289 10.4553C8.1048 10.4553 8.38197 10.1781 8.38197 9.83617C8.38197 9.49426 8.1048 9.21709 7.76289 9.21709V10.4553ZM4.66113 9.21709C4.31922 9.21709 4.04205 9.49426 4.04205 9.83617C4.04205 10.1781 4.31922 10.4553 4.66113 10.4553V9.21709ZM7.76445 12.9818C8.10636 12.9818 8.38353 12.7046 8.38353 12.3627C8.38353 12.0208 8.10636 11.7436 7.76445 11.7436V12.9818ZM6.75472 11.7436C6.41281 11.7436 6.13564 12.0208 6.13564 12.3627C6.13564 12.7046 6.41281 12.9818 6.75472 12.9818V11.7436ZM11.4784 8.50772L10.7687 5.60206L9.56592 5.89581L10.2755 8.80147L11.4784 8.50772ZM10.7685 5.60116C10.6823 5.25029 10.3682 5.00323 10.0069 5.00202L10.0027 6.24017C9.79562 6.23948 9.61558 6.09784 9.56614 5.89671L10.7685 5.60116ZM10.0048 5.00201H7.17283V6.24018H10.0048V5.00201ZM7.76289 9.21709H4.66113V10.4553H7.76289V9.21709ZM7.76445 11.7436H6.75472V12.9818H7.76445V11.7436Z"
                        fill="#525252"
                    />
                </svg>
            </button>
        </Link>
    );
}
