import { useTranslation } from "react-i18next";
import { getCurrency } from "../../lib/middleware";

export default function PaymentMethod({
    paymentMethods,
    paymentMethod,
    setPaymentMethod,
    total,
}: {
    paymentMethods: string[];
    paymentMethod: string;
    setPaymentMethod: (method: string) => void;
    total: number | null;
}) {
    const { t } = useTranslation();
    const styles = {
        imageContainer: {
            display: "flex",
            alignItems: "center",
        },
        tabbyContainer: {
            marginRight: 4,
            border: "1px solid #ccc",
            padding: "10px 4px",
            borderRadius: 4,
        },
        image: {
            width: 48,
            height: 20,
        },
    };
    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-medium text-[rgba(38,38,38,1)]">
                {t("payment-method")}
            </span>
            <div className="flex flex-col gap-2 rounded-xl border border-[rgba(229,229,229,1)] bg-white p-4">
                {paymentMethods.map((method, idx) => {
                    // console.log("Payment method:", method);
                    if (String(method) === "2") {
                        return (
                            <div className="contents" key={idx}>
                                <button
                                    className="flex gap-2 py-3"
                                    onClick={() => setPaymentMethod("2")}
                                >
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        {paymentMethod === "2" && (
                                            <circle
                                                cx="8.99969"
                                                cy="9.00018"
                                                r="6.68182"
                                                fill="#0A4C26"
                                            />
                                        )}
                                        <circle
                                            cx="8.99991"
                                            cy="9.00089"
                                            r="8.35227"
                                            stroke="#0B4C24"
                                            strokeWidth="1.11364"
                                        />
                                    </svg>
                                    <div className="flex flex-1 flex-col items-start gap-3.5">
                                        <span className="leading-none text-[rgba(64,64,64,1)]">
                                            {t("credit-debit-card")}
                                        </span>
                                        <div className="flex flex-wrap gap-2">
                                            <img
                                                className="h-[40px] w-[60px]"
                                                src="/payment/VISA_logo.png"
                                                alt="Visa"
                                            />
                                            <img
                                                className="h-[40px] w-[60px]"
                                                src="/payment/PayPal_logo.png"
                                                alt="PayPal"
                                            />
                                            <img
                                                className="h-[40px] w-[60px]"
                                                src="/payment/mastercard_logo.png"
                                                alt="Mastercard"
                                            />
                                            <img
                                                className="h-[40px] w-[60px]"
                                                src="/payment/GooglePay_logo.png"
                                                alt="GooglePay"
                                            />
                                            <img
                                                className="h-[40px] w-[60px]"
                                                src="/payment/ApplePay_logo.png"
                                                alt="ApplePa"
                                            />
                                        </div>
                                    </div>
                                </button>
                                {idx !== paymentMethods.length - 1 && (
                                    <hr className="border-t-[0.5px] border-[rgba(178,178,178,0.32)]" />
                                )}
                            </div>
                        );
                    } else if (String(method) === "1") {
                        return (
                            <div className="contents" key={idx}>
                                <button
                                    className="flex gap-2 py-3"
                                    onClick={() => setPaymentMethod("1")}
                                >
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        {paymentMethod === "1" && (
                                            <circle
                                                cx="8.99969"
                                                cy="9.00018"
                                                r="6.68182"
                                                fill="#0A4C26"
                                            />
                                        )}
                                        <circle
                                            cx="8.99991"
                                            cy="9.00089"
                                            r="8.35227"
                                            stroke="#0B4C24"
                                            strokeWidth="1.11364"
                                        />
                                    </svg>
                                    <div className="flex flex-1 flex-col items-start gap-3.5">
                                        <span className="leading-none text-[rgba(64,64,64,1)]">
                                            {t("cash-on-delivery")}
                                        </span>
                                        <div className="flex flex-wrap gap-2">
                                            <img
                                                className="h-[40px] w-[60px]"
                                                src="/payment/CASH_logo.png"
                                                alt="Cash"
                                            />
                                        </div>
                                    </div>
                                </button>
                                {idx !== paymentMethods.length - 1 && (
                                    <hr className="border-t-[0.5px] border-[rgba(178,178,178,0.32)]" />
                                )}
                            </div>
                        );
                    } else if (String(method) === "6") {
                        return (
                            <div className="contents" key={idx}>
                                <button
                                    className="flex gap-2 py-3"
                                    onClick={() => setPaymentMethod("6")}
                                >
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        {paymentMethod === "6" && (
                                            <circle
                                                cx="8.99969"
                                                cy="9.00018"
                                                r="6.68182"
                                                fill="#0A4C26"
                                            />
                                        )}
                                        <circle
                                            cx="8.99991"
                                            cy="9.00089"
                                            r="8.35227"
                                            stroke="#0B4C24"
                                            strokeWidth="1.11364"
                                        />
                                    </svg>
                                    <div className="flex flex-1 flex-col items-start gap-3.5">
                                        <span className="leading-none text-[rgba(64,64,64,1)]">
                                            {t("tamara")}
                                        </span>
                                        <div className="flex flex-wrap gap-2">
                                            <img
                                                className="h-[40px] w-[60px]"
                                                src="/payment/Tamara_logo.png"
                                                alt="Tamara"
                                            />
                                        </div>
                                    </div>
                                </button>
                                {idx !== paymentMethods.length - 1 && (
                                    <hr className="border-t-[0.5px] border-[rgba(178,178,178,0.32)]" />
                                )}
                            </div>
                        );
                    } else if (String(method) === "7") {
                        const cartTotal = total ? total : 0;
                        const installment = (cartTotal / 4).toFixed(2);
                        const currency = getCurrency();
                        const getPiePath = (fraction: number) => {
                            const size = 36;
                            const radius = size / 2;
                            const fullCircle = Math.PI * 2;

                            if (fraction === 1) {
                                return `M${radius},${radius} m-${radius},0 a${radius},${radius} 0 1,0 ${size},0 a${radius},${radius} 0 1,0 -${size},0 Z`;
                            }

                            const angle = fullCircle * fraction;
                            const largeArcFlag = fraction > 0.5 ? 1 : 0;

                            const x =
                                radius + radius * Math.cos(angle - Math.PI / 2);
                            const y =
                                radius + radius * Math.sin(angle - Math.PI / 2);

                            return `M${radius},${radius} L${radius},0 A${radius},${radius} 0 ${largeArcFlag},1 ${x},${y} Z`;
                        };

                        return (
                            <>
                                <div className="contents" key={idx}>
                                    <button
                                        className="flex gap-2 py-3"
                                        onClick={() => setPaymentMethod("7")}
                                    >
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            className="mt-1"
                                        >
                                            {paymentMethod === "7" && (
                                                <circle
                                                    cx="9"
                                                    cy="9"
                                                    r="6.7"
                                                    fill="#0A4C26"
                                                />
                                            )}
                                            <circle
                                                cx="9"
                                                cy="9"
                                                r="8.3"
                                                stroke="#0B4C24"
                                                strokeWidth="1.1"
                                            />
                                        </svg>
                                        <div className="flex flex-1 flex-col items-start gap-3.5">
                                            <span className="text-md text-red-500">
                                                Pay in 4. No interest, no fees
                                            </span>
                                            <div className="flex flex-wrap">
                                                <div
                                                    style={
                                                        styles.imageContainer
                                                    }
                                                >
                                                    <div
                                                        style={
                                                            styles.tabbyContainer
                                                        }
                                                    >
                                                        <img
                                                            src="/payment/tabby.png"
                                                            alt="Tabby"
                                                            style={styles.image}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </button>

                                    <div className="mt-1 flex flex-col items-center space-y-3">
                                        <p className="text-gray-700">
                                            Use any card.
                                        </p>
                                        <div className="flex items-center space-x-6">
                                            {[0.25, 0.5, 0.75, 1].map(
                                                (fraction, index) => (
                                                    <div
                                                        key={index}
                                                        className="flex flex-col items-center"
                                                    >
                                                        <svg
                                                            width="50"
                                                            height="50"
                                                            viewBox="0 0 36 36"
                                                        >
                                                            <circle
                                                                cx="18"
                                                                cy="18"
                                                                r="15"
                                                                fill="#D1D5DB"
                                                                stroke="none"
                                                            />
                                                            <path
                                                                d={getPiePath(
                                                                    fraction,
                                                                )}
                                                                fill="#14B8A6"
                                                            />
                                                        </svg>
                                                        <p className="mt-2 text-lg font-semibold">
                                                            {currency}{" "}
                                                            {installment}
                                                        </p>
                                                        <p className="text-sm text-gray-600">
                                                            {index === 0
                                                                ? "Today"
                                                                : `In ${index} month${index > 1 ? "s" : ""}`}
                                                        </p>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </div>

                                    {idx !== paymentMethods.length - 1 && (
                                        <hr className="border-t-[0.5px] border-[rgba(178,178,178,0.32)]" />
                                    )}
                                </div>
                            </>
                        );
                    } else return null;
                })}
            </div>
        </div>
    );
}
