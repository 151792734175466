import { useTranslation } from "react-i18next";
import useUserStore from "../../store/userStore";
import useModalsStore from "../../store/modalsStore";
import { cn } from "../../lib/utils";
import { useState } from "react";
import axios from "axios";
import SelectCountryCode from "../modals/select-country-code";
import { toast } from "react-toastify";
import LoadingSpinner from "../loading-spinner";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { add } from "date-fns";

export default function AddNewAddress() {
    const { t } = useTranslation();
    const region = useUserStore((state) => state.region);
    const emirate = useUserStore((state) => state.emirate);
    const setMapModalOpen = useModalsStore((state) => state.setMapModalOpen);
    const latLng = useUserStore((state) => state.latLng);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<AddressSchemaType>({
        resolver: zodResolver(AddressSchema),
    });
    const [countryCode, setCountryCode] = useState("971");
    const [isCountryCodeSelecting, setIsCountryCodeSelecting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const onSubmit: SubmitHandler<AddressSchemaType> = async (data) => {
        try {
            data.name = data.name.replace(/\s+/g, "");
            data.streetName = data.streetName.replace(/\s+/g, "");
            data.buildingNumber = data.buildingNumber.replace(/\s+/g, "");
            data.apartmentNumber = data.apartmentNumber.replace(/\s+/g, "");
            data.additionalNumber = data.additionalNumber.replace(/\s+/g, "");
            if (
                data.name === "" ||
                data.streetName === "" ||
                data.buildingNumber === "" ||
                data.apartmentNumber === "" ||
                data.additionalNumber === ""
            ) {
                toast.error("Please enter valid input for all the fields");
                return false;
            }
            setLoading(true);
            const formData = new FormData();
            const address = editMode
                ? addressObj?.emirate?.name
                : emirate?.name!;
            const regionId = editMode
                ? addressObj?.region?.id.toString()
                : region?.id.toString()!;
            const lat = latLng?.lat.toString()! || "";
            const lng = latLng?.lng.toString()! || "";
            formData.append("name", data.name);
            formData.append("address", address);
            formData.append("street_name", data.streetName);
            formData.append("apartment_num", data.apartmentNumber);
            formData.append("region_id", regionId);
            formData.append("lat", lat);
            formData.append("lng", lng);
            formData.append("country_code", countryCode);
            formData.append("mobile", data.mobile);
            formData.append("building_number", data.buildingNumber);
            formData.append("additional_number", data.additionalNumber);
            formData.append("address_type", addressType);
            formData.append("notes", data.additionalNumber);
            if (editMode) {
                formData.append("address_id", addressObj?.id);
                await axios.post("/user/address/update", formData);
                navigate(
                    searchParams.get("redirectTo") || "/account/addresses",
                );
                toast.success(t("saved-access-success"));
            } else {
                await axios.post("/user/address/add", formData);
                window.location.reload();
                setMapModalOpen(false);
                toast.success(t("saved-access-success"));
            }
        } catch {
            toast.error(t("something-went-wrong"));
        }
        setLoading(false);
    };

    const location = useLocation();
    const { editMode, addressObj } = location.state || {};
    const [address, setAddress] = useState(
        editMode
            ? `${addressObj?.address}`
            : `${region?.name} - ${emirate?.name}`,
    );
    const [name, setName] = useState(editMode ? `${addressObj?.name}` : "");
    const [streetName, setStreetName] = useState(
        editMode ? `${addressObj?.street_name}` : "",
    );
    const [buildingNumber, setBuildingNumber] = useState(
        editMode ? `${addressObj?.building_number}` : "",
    );
    const [apartmentNumber, setApartmentNumber] = useState(
        editMode ? `${addressObj?.apartment_num}` : "",
    );
    const [additionalNotes, setAdditionalNotes] = useState(
        editMode ? `${addressObj?.notes || ""}` : "",
    );
    const [mobile, setMobile] = useState(
        editMode ? addressObj?.mobile || "" : "",
    );
    const [addressType, setAddressType] = useState(
        editMode ? addressObj.address_type : "home",
    );

    const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(e.target.value);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleStreetNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStreetName(e.target.value);
    };

    const handleBuildingNumberChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setBuildingNumber(e.target.value);
    };

    const handleApartmentNumberChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setApartmentNumber(e.target.value);
    };

    const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMobile(e.target.value);
    };

    const handleAdditionalNotesChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setAdditionalNotes(e.target.value);
    };

    const handleAddressTypeChange = (value: string) => {
        setAddressType(value);
    };

    return (
        <div
            className={cn("ms-auto max-w-[917px] px-4", {
                "me-auto pb-28 pt-14": location.pathname === "/add-new-address",
            })}
        >
            <h2 className="mb-10 text-center text-[28px] font-medium text-[rgba(23,23,23,1)]">
                {editMode ? t("edit-address") : t("add-new-address")}
            </h2>
            <div className="flex flex-col">
                <button
                    onClick={() => {
                        setMapModalOpen(true);
                    }}
                    className="mb-4 flex flex-col rounded-lg border border-[rgba(229,229,229,1)] bg-white p-4 transition hover:opacity-80"
                >
                    <div className="flex items-center gap-2">
                        <svg
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mt-0.5"
                        >
                            <path
                                d="M13.0001 14.5491C14.8668 14.5491 16.3801 13.0358 16.3801 11.1691C16.3801 9.30234 14.8668 7.78906 13.0001 7.78906C11.1334 7.78906 9.62012 9.30234 9.62012 11.1691C9.62012 13.0358 11.1334 14.5491 13.0001 14.5491Z"
                                stroke="currentColor"
                                strokeWidth="1.625"
                            />
                            <path
                                d="M3.92168 9.19685C6.05584 -0.184815 19.955 -0.173982 22.0783 9.20769C23.3242 14.711 19.9008 19.3694 16.9 22.251C14.7225 24.3527 11.2775 24.3527 9.08918 22.251C6.09918 19.3694 2.67584 14.7002 3.92168 9.19685Z"
                                stroke="#292D32"
                                strokeWidth="1.625"
                            />
                        </svg>
                        <span className="mb-0.5 text-xl font-medium text-[rgba(23,23,23,1)]">
                            {t("location-from-the-map")}
                        </span>
                    </div>
                    <span
                        onChange={handleAddressChange}
                        className="mb-0.5 ms-[36px] text-[rgba(82,82,82,1)]"
                    >
                        {address}
                    </span>
                </button>
                <div className="flex flex-col gap-3">
                    <div>
                        <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:text-[rgba(229,229,229,1)]">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <input
                                type="text"
                                placeholder={t("Address name *")}
                                value={name}
                                className="flex-1 focus:outline-none"
                                {...register("name", {
                                    required: "Name is required",
                                    onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => handleNameChange(e),
                                })}
                            />
                        </div>
                        <div>
                            {errors.name && (
                                <span className="ms-2 text-red-500">
                                    {errors.name.message &&
                                        `${t(errors.name.message)} ${t("is-required")}`}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:text-[rgba(229,229,229,1)]">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M6.70001 18H4.15002C2.72002 18 2 17.28 2 15.85V4.15002C2 2.72002 2.72002 2 4.15002 2H8.45001C9.88001 2 10.6 2.72002 10.6 4.15002V6"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M17.3699 8.41998V19.58C17.3699 21.19 16.57 22 14.96 22H9.11993C7.50993 22 6.69995 21.19 6.69995 19.58V8.41998C6.69995 6.80998 7.50993 6 9.11993 6H14.96C16.57 6 17.3699 6.80998 17.3699 8.41998Z"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M13.4001 6V4.15002C13.4001 2.72002 14.1201 2 15.5501 2H19.8501C21.2801 2 22.0001 2.72002 22.0001 4.15002V15.85C22.0001 17.28 21.2801 18 19.8501 18H17.3701"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M10 11H14"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M10 14H14"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M12 22V19"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <span className="flex-1">
                            {editMode
                                ? `${addressObj?.emirate?.name}`
                                : `${emirate?.name}`}
                        </span>
                    </div>
                    <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:text-[rgba(229,229,229,1)]">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M22 9.0005V15.0005C22 17.5005 21.5 19.2505 20.38 20.3805L14 14.0005L21.73 6.27051C21.91 7.06051 22 7.9605 22 9.0005Z"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M21.73 6.27L6.26999 21.73C3.25999 21.04 2 18.96 2 15V9C2 4 4 2 9 2H15C18.96 2 21.04 3.26 21.73 6.27Z"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M20.38 20.38C19.25 21.5 17.5 22 15 22H9.00003C7.96003 22 7.06002 21.91 6.27002 21.73L14 14L20.38 20.38Z"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M6.24002 7.97973C6.92002 5.04973 11.32 5.04973 12 7.97973C12.39 9.69973 11.31 11.1597 10.36 12.0597C9.67001 12.7197 8.58003 12.7197 7.88003 12.0597C6.93003 11.1597 5.84002 9.69973 6.24002 7.97973Z"
                                stroke="#262626"
                                strokeWidth="1.5"
                            />
                            <path
                                d="M9.09461 8.7002H9.10359"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>

                        <span className="flex-1">
                            {editMode
                                ? `${addressObj?.region?.name}`
                                : `${region?.name}` || ""}
                        </span>
                    </div>
                    <div>
                        <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:text-[rgba(229,229,229,1)]">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.07006 4.59988C2.87006 1.13988 8.08006 1.13988 8.87006 4.59988C9.34006 6.62988 8.05006 8.34988 6.93006 9.41988C6.11006 10.1999 4.82006 10.1899 4.00006 9.41988C2.89006 8.34988 1.60006 6.62988 2.07006 4.59988Z"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                />
                                <path
                                    d="M15.07 16.6009C15.87 13.1409 21.11 13.1409 21.91 16.6009C22.38 18.6309 21.09 20.3509 19.96 21.4209C19.14 22.2009 17.84 22.1909 17.02 21.4209C15.89 20.3509 14.6 18.6309 15.07 16.6009Z"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                />
                                <path
                                    d="M11.9999 5H14.6799C16.5299 5 17.3899 7.29 15.9999 8.51L8.00995 15.5C6.61995 16.71 7.47994 19 9.31994 19H11.9999"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M5.48622 5.5H5.49777"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M18.4862 17.5H18.4978"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <input
                                type="text"
                                value={streetName}
                                placeholder={t("street-name")}
                                className="flex-1 focus:outline-none"
                                {...register("streetName", {
                                    required: "Street Name is required",
                                    onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => handleStreetNameChange(e),
                                })}
                            />
                        </div>
                        <div>
                            {errors.streetName && (
                                <span className="ms-2 text-red-500">
                                    {errors.streetName.message &&
                                        `${t(errors.streetName.message)} ${t("is-required")}`}
                                </span>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:text-[rgba(229,229,229,1)]">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13 22H5C3 22 2 21 2 19V11C2 9 3 8 5 8H10V19C10 21 11 22 13 22Z"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M10.11 4C10.03 4.3 10 4.63 10 5V8H5V6C5 4.9 5.9 4 7 4H10.11Z"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M14 8V13"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M18 8V13"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M17 17H15C14.45 17 14 17.45 14 18V22H18V18C18 17.45 17.55 17 17 17Z"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 13V17"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M10 19V5C10 3 11 2 13 2H19C21 2 22 3 22 5V19C22 21 21 22 19 22H13C11 22 10 21 10 19Z"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>

                            <input
                                type="text"
                                value={buildingNumber}
                                placeholder={t("building-number")}
                                className="flex-1 focus:outline-none"
                                {...register("buildingNumber", {
                                    required: "Building Number is required",
                                    onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => handleBuildingNumberChange(e),
                                })}
                            />
                        </div>
                        <div>
                            {errors.buildingNumber && (
                                <span className="ms-2 text-red-500">
                                    {errors.buildingNumber.message &&
                                        `${t(errors.buildingNumber.message)} ${t("is-required")}`}
                                </span>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:text-[rgba(229,229,229,1)]">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.5 10.5H8.5C7.67 10.5 7 11.17 7 12V21.5H17V12C17 11.17 16.33 10.5 15.5 10.5Z"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M10 15.75V17.25"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                    stroke="#262626"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <input
                                type="text"
                                value={apartmentNumber}
                                placeholder={t("apartment-number")}
                                className="flex-1 focus:outline-none"
                                {...register("apartmentNumber", {
                                    required: "Apartment Number is required",
                                    onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => handleApartmentNumberChange(e),
                                })}
                            />
                        </div>
                        <div>
                            {errors.apartmentNumber && (
                                <span className="ms-2 text-red-500">
                                    {errors.apartmentNumber.message &&
                                        `${t(errors.apartmentNumber.message)} ${t("is-required")}`}
                                </span>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:text-[rgba(229,229,229,1)]">
                            <textarea
                                value={additionalNotes}
                                placeholder={t("additional-number")}
                                className="flex-1 resize-none focus:outline-none"
                                rows={3}
                                {...register("additionalNumber", {
                                    required: "Additional Tips is required",
                                    onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => handleAdditionalNotesChange(e),
                                })}
                            />
                        </div>
                        <div>
                            {errors.additionalNumber && (
                                <span className="ms-2 text-red-500">
                                    {errors.additionalNumber.message &&
                                        `${t(errors.additionalNumber.message)} ${t("is-required")}`}
                                </span>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center gap-2 rounded-lg border border-[rgba(229,229,229,1)] bg-white px-4 py-[18px] text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] placeholder:text-[rgba(229,229,229,1)]">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14.647 12.22c0 .24-.054.487-.167.727a2.75 2.75 0 01-.453.68c-.327.36-.687.62-1.094.786a3.35 3.35 0 01-1.3.254c-.68 0-1.406-.16-2.173-.487a11.703 11.703 0 01-2.293-1.32 19.173 19.173 0 01-2.187-1.867 18.948 18.948 0 01-1.86-2.18c-.547-.76-.987-1.52-1.307-2.273-.32-.76-.48-1.487-.48-2.18 0-.453.08-.887.24-1.287.16-.406.414-.78.767-1.113.427-.42.893-.627 1.387-.627.186 0 .373.04.54.12.173.08.326.2.446.374l1.547 2.18c.12.166.207.32.267.466.06.14.093.28.093.407 0 .16-.047.32-.14.473a2.268 2.268 0 01-.373.474l-.507.526a.357.357 0 00-.107.267c0 .053.007.1.02.153.02.054.04.094.054.134.12.22.326.506.62.853.3.347.62.7.966 1.053.36.354.707.68 1.06.98.347.294.634.494.86.614.034.013.074.033.12.053a.46.46 0 00.167.027c.113 0 .2-.04.273-.114l.507-.5c.167-.166.327-.293.48-.373a.888.888 0 01.473-.14c.127 0 .26.027.407.087.147.06.3.146.467.26l2.206 1.566c.174.12.294.26.367.427.067.167.107.333.107.52z"
                                    stroke="#262626"
                                    strokeMiterlimit={10}
                                />
                            </svg>
                            <input
                                type="text"
                                value={mobile}
                                className="flex-1 focus:outline-none"
                                placeholder={t("modals.mobile")}
                                {...register("mobile", {
                                    onChange: (e) => handleMobileChange(e),
                                })}
                            />
                            <button
                                className="flex items-center transition hover:opacity-80"
                                onClick={() => setIsCountryCodeSelecting(true)}
                            >
                                +{countryCode}
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_258_154458)">
                                        <path
                                            d="M4.66516 5.86704L6.10796 7.55893C6.32521 7.81369 6.67616 7.81369 6.89342 7.55893L8.33622 5.86704C8.68717 5.4555 8.43649 4.75 7.9407 4.75H5.0551C4.55931 4.75 4.31421 5.4555 4.66516 5.86704Z"
                                            fill="#525252"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_258_154458">
                                            <rect
                                                width="11.5"
                                                height="11.5"
                                                fill="white"
                                                transform="translate(0.5 0.5)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                        </div>
                        <div>
                            {errors.mobile && (
                                <span className="ms-2 text-red-500">
                                    {errors.mobile.message &&
                                        `${t(errors.mobile.message)} ${t("is-required")}`}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex flex-col">
                    <span className="mb-[18px] text-xl font-medium text-[rgba(38,38,38,1)]">
                        {t("address-type")}
                    </span>
                    <div className="flex justify-center gap-3">
                        <button
                            className={cn(
                                "flex items-center justify-center gap-1.5 rounded-md px-3 py-2.5 font-light text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)]",
                                {
                                    "bg-primary text-white":
                                        addressType === "home",
                                },
                            )}
                            onClick={() => handleAddressTypeChange("home")}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.02 2.84016L3.63 7.04016C2.73 7.74016 2 9.23016 2 10.3602V17.7702C2 20.0902 3.89 21.9902 6.21 21.9902H17.79C20.11 21.9902 22 20.0902 22 17.7802V10.5002C22 9.29016 21.19 7.74016 20.2 7.05016L14.02 2.72016C12.62 1.74016 10.37 1.79016 9.02 2.84016Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M12 17.9902V14.9902"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>

                            <span>{t("home")}</span>
                        </button>
                        <button
                            className={cn(
                                "flex items-center justify-center gap-1.5 rounded-md px-3 py-2.5 font-light text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)]",
                                {
                                    "bg-primary text-white":
                                        addressType === "work",
                                },
                            )}
                            onClick={() => handleAddressTypeChange("work")}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13 22H5C3 22 2 21 2 19V11C2 9 3 8 5 8H10V19C10 21 11 22 13 22Z"
                                    stroke="currentColor"
                                    strokeWidth="1.34028"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M10.11 4C10.03 4.3 10 4.63 10 5V8H5V6C5 4.9 5.9 4 7 4H10.11Z"
                                    stroke="currentColor"
                                    strokeWidth="1.34028"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M14 8V13"
                                    stroke="currentColor"
                                    strokeWidth="1.34028"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M18 8V13"
                                    stroke="currentColor"
                                    strokeWidth="1.34028"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M17 17H15C14.45 17 14 17.45 14 18V22H18V18C18 17.45 17.55 17 17 17Z"
                                    stroke="currentColor"
                                    strokeWidth="1.34028"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 13V17"
                                    stroke="currentColor"
                                    strokeWidth="1.34028"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M10 19V5C10 3 11 2 13 2H19C21 2 22 3 22 5V19C22 21 21 22 19 22H13C11 22 10 21 10 19Z"
                                    stroke="currentColor"
                                    strokeWidth="1.34028"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>

                            <span>{t("work")}</span>
                        </button>
                        <button
                            className={cn(
                                "flex items-center justify-center gap-1.5 rounded-md px-3 py-2.5 font-light text-[rgba(38,38,38,1)] shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)]",
                                {
                                    "bg-primary text-white":
                                        addressType === "other",
                                },
                            )}
                            onClick={() => handleAddressTypeChange("other")}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 13.4295C13.7231 13.4295 15.12 12.0326 15.12 10.3095C15.12 8.58633 13.7231 7.18945 12 7.18945C10.2769 7.18945 8.88 8.58633 8.88 10.3095C8.88 12.0326 10.2769 13.4295 12 13.4295Z"
                                    stroke="currentColor"
                                    strokeWidth="1.33333"
                                />
                                <path
                                    d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z"
                                    stroke="currentColor"
                                    strokeWidth="1.33333"
                                />
                            </svg>

                            <span>{t("other")}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="mx-auto mt-10 flex w-full max-w-[368px] flex-col gap-4">
                <button
                    onClick={handleSubmit(onSubmit)}
                    className="flex items-center justify-center rounded-lg border border-primary bg-primary px-4 py-[18px] text-white transition hover:opacity-80"
                >
                    {loading ? <LoadingSpinner /> : t("save")}
                </button>
            </div>
            {isCountryCodeSelecting && (
                <div className="ReactModal__Overlay ReactModal__Overlay--after-open fixed">
                    <div className="ReactModal__Content ReactModal__Content--after-open bg-white">
                        <div className="flex flex-col">
                            <div className="mb-4 flex">
                                <button
                                    className="ms-auto transition hover:scale-125"
                                    onClick={() =>
                                        setIsCountryCodeSelecting(false)
                                    }
                                >
                                    <svg
                                        width="32"
                                        height="33"
                                        viewBox="0 0 32 33"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.07975 8.43587C8.70459 7.81103 9.71765 7.81103 10.3425 8.43587L15.9993 14.0927L21.6562 8.43587C22.281 7.81103 23.2941 7.81103 23.9189 8.43587C24.5438 9.06071 24.5438 10.0738 23.9189 10.6986L18.2621 16.3555L23.9189 22.0123C24.5438 22.6372 24.5438 23.6502 23.9189 24.2751C23.2941 24.8999 22.281 24.8999 21.6562 24.2751L15.9993 18.6182L10.3425 24.2751C9.71765 24.8999 8.70459 24.8999 8.07975 24.2751C7.45491 23.6502 7.45491 22.6372 8.07975 22.0123L13.7366 16.3555L8.07975 10.6986C7.45491 10.0738 7.45491 9.06071 8.07975 8.43587Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <SelectCountryCode
                                setCountryCode={setCountryCode}
                                countryCode={countryCode}
                                setIsCountryCodeSelecting={
                                    setIsCountryCodeSelecting
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const AddressSchema = z.object({
    name: z.string().min(1, "name"),
    mobile: z.string().min(1, "mobile"),
    buildingNumber: z.string().min(1, "building-number"),
    streetName: z.string().min(1, "street-name"),
    apartmentNumber: z.string().min(1, "apartment-number"),
    additionalNumber: z.string().min(1, "additional-number"),
});

type AddressSchemaType = z.infer<typeof AddressSchema>;
