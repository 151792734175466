import React from "react";
import Accordion from "../accordion";
import { useTranslation } from "react-i18next";
import { getCurrency } from "../../lib/middleware";
import { useState, useEffect } from "react";

const SubProductsSection = ({ data, setSubProductID, subProductID }) => {
    const { i18n, t } = useTranslation();
    const currency = getCurrency();
    return (
        <Accordion title={t("available-weight")}>
            <div className="flex flex-col gap-y-1">
                {data.sub_product.map((subProduct, index) => (
                    <div className="contents" key={subProduct.id}>
                        <button
                            key={subProduct.id}
                            className="flex w-full items-center justify-between gap-2 py-4"
                            onClick={() => setSubProductID(subProduct.id)}
                        >
                            <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                {subProductID === subProduct.id && (
                                    <circle
                                        cx="14.0002"
                                        cy="14.0002"
                                        r="6.68182"
                                        fill="#0A4C26"
                                    />
                                )}
                                <circle
                                    cx="13.9999"
                                    cy="14.0009"
                                    r="8.35227"
                                    stroke="#0B4C24"
                                    strokeWidth="1.11364"
                                />
                            </svg>

                            <div className="flex flex-1 justify-between">
                                <span className="text-[rgba(38,38,38,1)]">
                                    {subProduct.weight}
                                </span>
                                <span className="tracking-widest text-[rgba(82,82,82,1)]">
                                    {subProduct.age}
                                </span>
                            </div>
                            <span className="flex-1 text-end text-[rgba(82,82,82,1)]">
                                {subProduct.price} {t(currency || "AED")}
                            </span>
                        </button>
                        {data.sub_product &&
                            index !== data.sub_product.length - 1 && (
                                <hr className="border-t-[0.5px] border-[rgba(178,178,178,0.32)]" />
                            )}
                    </div>
                ))}
            </div>
        </Accordion>
    );
};

export default SubProductsSection;
