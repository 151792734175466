import React from "react";
import { useTranslation } from "react-i18next";
import TextAreaSection from "./AttributesSections/TextAreaSection";
import WithoutSection from "./AttributesSections/WithoutSection";
import CuttingsSection from "./AttributesSections/CuttingsSection";
import PackagingSection from "./AttributesSections/PackagingSection";
import ChooseItemList from "./AttributesSections/ChooseItemList";
// import SpecificationsSection from "./AttributesSections/SpecificationsSection";
// import ParagraphSection from "./AttributesSections/ParagraphSection";

const Attributes = ({ data, cartAttributes, setCartAttributes }) => {
    const { i18n, t } = useTranslation();

    const pushAttribute = (attribute_id, value_id, multi_values = false) => {
        if (multi_values) {
            if (cartAttributes.hasOwnProperty(attribute_id)) {
                value_id = cartAttributes[attribute_id].value_id.includes(
                    value_id,
                )
                    ? cartAttributes[attribute_id].value_id.filter(
                          (val) => val !== value_id,
                      )
                    : [...cartAttributes[attribute_id].value_id, value_id];
            } else {
                value_id = [value_id];
            }
        }
        cartAttributes[attribute_id] = {
            attribute_id,
            value_id,
        };

        setCartAttributes({ ...cartAttributes });
    };

    const pushAttribute1 = (attributeId, valueId, price) => {
        setCartAttributes((prev) => ({
            ...prev,
            [attributeId]: { value_id: valueId, price: parseFloat(price) || 0 },
        }));
    };

    return (
        <>
            {data.attributes &&
                data.attributes.length > 0 &&
                data.attributes.map((attribute) => {
                    switch (attribute.section_name) {
                        case "textarea":
                            return (
                                <TextAreaSection
                                    key={attribute.id}
                                    attribute={attribute}
                                />
                            );
                        case "without":
                            return (
                                <WithoutSection
                                    key={attribute.id}
                                    attribute={attribute}
                                    cartAttributes={cartAttributes}
                                    pushAttribute={pushAttribute}
                                />
                            );
                        case "cutting":
                            return (
                                <CuttingsSection
                                    key={attribute.id}
                                    data={data}
                                    attribute={attribute}
                                    cartAttributes={cartAttributes}
                                    pushAttribute={pushAttribute}
                                />
                            );
                        case "packaging":
                            return (
                                <PackagingSection
                                    key={attribute.id}
                                    data={data}
                                    attribute={attribute}
                                    cartAttributes={cartAttributes}
                                    pushAttribute={pushAttribute}
                                />
                            );
                        // case "specifications":
                        //     return (
                        //         <SpecificationsSection
                        //             key={attribute.id}
                        //             attribute={attribute}
                        //         />
                        //     );
                        // case "paragraph":
                        //     return (
                        //         <ParagraphSection
                        //             key={attribute.id}
                        //             attribute={attribute}
                        //         />
                        //     );
                        // case "list-multi":
                        // case "list-multi-price":
                        //     return (
                        //         <ChooseItemList
                        //             key={attribute.id}
                        //             attribute={attribute}
                        //             cartAttributes={cartAttributes}
                        //             pushAttribute={(attr_id, val_id) =>
                        //                 pushAttribute(attr_id, val_id, true)
                        //             }
                        //             multiSelect={true}
                        //         />
                        //     );
                        case "list-single":
                        case "list-single-price":
                            return (
                                <ChooseItemList
                                    key={attribute.id}
                                    attribute={attribute}
                                    cartAttributes={cartAttributes}
                                    pushAttribute={pushAttribute1}
                                />
                            );
                        default:
                            return null;
                    }
                })}
        </>
    );
};

export default Attributes;
