import React from "react";
import Accordion from "../../accordion";
import { useTranslation } from "react-i18next";
import { getCurrency } from "../../../lib/middleware";

const ChooseItemList = ({ attribute, cartAttributes, pushAttribute }) => {
    const { t } = useTranslation();
    const currency = getCurrency() || "AED";

    return (
        <Accordion title={attribute.name} key={attribute.id}>
            <div className="flex flex-col gap-y-1">
                {attribute.values.map((value, index) => {
                    const isSelected =
                        cartAttributes[attribute.id]?.value_id === value.id;

                    return (
                        <div className="contents" key={value.id}>
                            <button
                                className="flex w-full items-center justify-between gap-2 py-4"
                                onClick={() =>
                                    pushAttribute(
                                        attribute.id,
                                        value.id,
                                        value.price ?? "0",
                                    )
                                }
                            >
                                {/* Radio Button */}
                                <svg
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ marginRight: "10px" }}
                                >
                                    {isSelected && (
                                        <circle
                                            cx="14.0002"
                                            cy="14.0002"
                                            r="6.68182"
                                            fill="#0A4C26"
                                        />
                                    )}
                                    <circle
                                        cx="13.9999"
                                        cy="14.0009"
                                        r="8.35227"
                                        stroke="#0B4C24"
                                        strokeWidth="1.11364"
                                    />
                                </svg>

                                {/* Item Name */}
                                <span className="flex-1 text-[rgba(38,38,38,1)]">
                                    {value.value}
                                </span>

                                {/* Price Display */}
                                {value.price && (
                                    <span className="text-end text-[rgba(82,82,82,1)]">
                                        {value.price} {t(currency)}
                                    </span>
                                )}
                            </button>

                            {/* Divider (except for last item) */}
                            {index !== attribute.values.length - 1 && (
                                <hr className="border-t-[0.5px] border-[rgba(178,178,178,0.32)]" />
                            )}
                        </div>
                    );
                })}
            </div>
        </Accordion>
    );
};

export default ChooseItemList;
