import { cn } from "../../lib/utils";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import useUserStore from "../../store/userStore";
import { getCurrency } from "../../lib/middleware";
import { useState, useEffect } from "react";

const UseBalance = ({
    useBalance,
    setUseBalance,
}: {
    useBalance: boolean;
    setUseBalance: Dispatch<SetStateAction<boolean>>;
}) => {
    const { t } = useTranslation();
    const userBalance = useUserStore((state) => state.user?.balance);

    const currency = getCurrency();

    return (
        <div className="flex w-full overflow-hidden rounded-lg border-y border-s border-[rgba(229,229,229,1)] bg-white">
            <div className="flex flex-1 items-center gap-2 px-5 py-[18px]">
                <div className="w-0 flex-1 bg-transparent pb-0.5 text-primary placeholder:text-primary focus:outline-none">
                    {t("in-your-wallet") +
                        ` ${+userBalance!} ` +
                        t(currency || "AED")}
                    {/* ({userBalance!} {t(currency)}) */}
                </div>
            </div>
            <button
                onClick={() => setUseBalance(!useBalance)}
                className={cn(
                    "flex w-20 items-center justify-center bg-primary px-3 text-white transition hover:opacity-80 sm:w-40",
                    {
                        "bg-[rgba(240,61,67,1)]": useBalance,
                    },
                )}
            >
                {useBalance ? t("cancel") : t("use")}
            </button>
        </div>
    );
};

export default UseBalance;
