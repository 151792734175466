import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/a11y";
import "swiper/css/autoplay";
import { useTranslation } from "react-i18next";
import { Slider } from "../../types/home-page";

export default function Hero({ sliders }: { sliders: Slider[] }) {
    const { i18n } = useTranslation();

    return (
        <Swiper
            modules={[Navigation, Pagination, A11y, Autoplay]}
            spaceBetween={24}
            slidesPerView={1}
            navigation={{
                enabled: true,
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            }}
            pagination={{ clickable: true }}
            loop
            autoplay={{
                delay: 3000,
            }}
            style={
                {
                    "--swiper-pagination-color": "rgba(245, 245, 245, 1)",
                    "--swiper-pagination-bullet-inactive-color": "transparent",
                    "--swiper-pagination-bullet-inactive-opacity": "1",
                    "--swiper-pagination-bullet-size": "17.5px",
                    "--swiper-pagination-bullet-horizontal-gap": "10px",
                } as React.CSSProperties
            }
            dir={i18n.dir.toString()}
            className="w-full overflow-hidden rounded-[20px]"
            key={i18n.language + "hero"}
        >
            <div className="swiper-button-next !flex !size-12 !items-center !justify-center rounded-full bg-[rgba(255,255,255,0.4)] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] transition hover:bg-white xl:!size-12">
                <svg
                    width="12"
                    height="22"
                    viewBox="0 0 12 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="!h-[22px] !w-[12px]"
                >
                    <path
                        d="M1.125 0.828125L0.1875 1.71875C0 1.95312 0 2.32812 0.1875 2.51562L8.67188 11L0.1875 19.5312C0 19.7188 0 20.0938 0.1875 20.3281L1.125 21.2188C1.35938 21.4531 1.6875 21.4531 1.92188 21.2188L11.7656 11.4219C11.9531 11.1875 11.9531 10.8594 11.7656 10.625L1.92188 0.828125C1.6875 0.59375 1.35938 0.59375 1.125 0.828125Z"
                        fill="#171717"
                    />
                </svg>
            </div>
            <div className="swiper-button-prev !flex !size-12 !items-center !justify-center rounded-full bg-[rgba(255,255,255,0.4)] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] transition hover:bg-white">
                <svg
                    viewBox="0 0 12 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="!h-[22px] !w-[12px]"
                >
                    <path
                        d="M10.875 21.1719L11.8125 20.2812C12 20.0469 12 19.6719 11.8125 19.4844L3.32812 11L11.8125 2.46875C12 2.28125 12 1.90625 11.8125 1.67188L10.875 0.78125C10.6406 0.546875 10.3125 0.546875 10.0781 0.78125L0.234375 10.5781C0.046875 10.8125 0.046875 11.1406 0.234375 11.375L10.0781 21.1719C10.3125 21.4062 10.6406 21.4062 10.875 21.1719Z"
                        fill="#171717"
                    />
                </svg>
            </div>
            {sliders.map((slide) => (
                <SwiperSlide key={slide.id}>
                    {slide.page_link && slide.page_params ? (
                        <a
                            href={`${slide.page_link === "categories" ? "category" : slide.page_link}/${slide.page_params}`}
                        >
                            <img
                                src={slide.image}
                                alt={slide.id.toString()}
                                className="min-h-[300px] w-full object-contain xl:h-[600px]"
                            />
                        </a>
                    ) : (
                        <img
                            src={slide.image}
                            alt={slide.id.toString()}
                            className="min-h-[300px] w-full object-contain xl:h-[600px]"
                        />
                    )}
                </SwiperSlide>
            ))}
        </Swiper>
    );
}
