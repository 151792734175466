import { useTranslation } from "react-i18next";
import NoDataIcon from "../../assets/no-data-icon";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { getCurrency } from "../../lib/middleware";

export default function Orders() {
    const { t } = useTranslation();
    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const currency = getCurrency();

    const fetchOrders = useCallback(async () => {
        if (loading || !hasMore) return;

        setLoading(true);

        try {
            const response = await axios.get(`/user/orders`, {
                params: { page },
            });
            const newOrders = response.data.data;

            setOrders((prevOrders) => [...prevOrders, ...newOrders]);
            setHasMore(newOrders.length > 0);
            setPage((prevPage) => prevPage + 1);
        } catch (error) {
            console.error("Error fetching orders:", error);
        } finally {
            setLoading(false);
        }
    }, [page, loading, hasMore]);

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders]);

    const handleScroll = useCallback(() => {
        if (
            window.innerHeight + document.documentElement.scrollTop !==
            document.documentElement.offsetHeight
        )
            return;
        fetchOrders();
    }, [fetchOrders]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);

    return (
        // <div className="mx-auto flex max-w-[852px] flex-col items-center gap-10 px-4 pb-28 pt-14">
        //     <h2 className="text-center text-[40px] font-medium text-[rgba(23,23,23,1)]">
        //         {t("account.orders")}
        //     </h2>
        //     <NoDataIcon className="mt-[120px]" />
        // </div>

        <div className="container mx-auto space-y-4 p-4">
            {orders.length === 0 && !loading ? (
                <NoDataIcon className="mt-[120px]" />
            ) : (
                orders.map((order, index) => {
                    if (!order.details) return null;
                    return (
                        <div
                            key={index}
                            className="rounded-lg border p-4 shadow-sm"
                        >
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <img
                                        src={order.details[0].product.image}
                                        className="ml-4 h-32 w-32 rounded-lg"
                                    />
                                    <div className="ml-4">
                                        <h2 className="text-lg font-semibold">
                                            {order.details[0].product.name}
                                        </h2>
                                        <p className="text-gray-600">
                                            #{order.id}
                                        </p>
                                        <p className="text-500">
                                            {order.total} ${currency || "AED"}
                                        </p>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <p className=" h-[27px] w-[27px] rounded-full text-gray-600 shadow">
                                        {order.details.length}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-4 flex items-center">
                                <img
                                    src="/delivery-icon.png"
                                    alt="Delivery"
                                    className="w-25 h-25"
                                />
                                <div className="ml-4">
                                    <p className="text-gray-600">
                                        Order Time & Date
                                    </p>
                                    {order.time && (
                                        <p className="font-semibold">
                                            {order.time["from"] +
                                                "-" +
                                                order.time["to"]}
                                        </p>
                                    )}
                                    <p className="font-semibold">
                                        {order.date}
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                })
            )}
            {loading && <div className="text-center">Loading...</div>}
        </div>
    );
}
